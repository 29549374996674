 <template>
  <div class="w-100">
    <div class="play-loader" v-if="showLoader">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="2" />
      </content-placeholders>
    </div>

    <div
      class="d-flex align-items-center flex-wrap justify-content-between"
      style="gap:15px;"
      v-else-if="eventData && eventData.length"
    >
      <div
        class="d-flex align-items-center flex-column"
        v-for="(dataItem, index) in eventData"
        :key="index"
      >
        <div class="weather-date">
          <div class="date-part">
            {{ formatDateWeather(dataItem.day).date }}
          </div>
        </div>
        <img
          :src="iconUrl + dataItem.icon"
          alt="Weather icon"
          class="weather-image"
        />
        <div class="tempr">
          <span class="high">
            {{ Math.round(dataItem.temperatureMax) }}&deg;F
          </span>
          <span class="low">
            {{ Math.round(dataItem.temperatureMin) }}&deg;F
          </span>
        </div>
      </div>
    </div>

    
  </div>
</template>
  
  <script>
import axios from "axios";

export default {
  name: "WeatherData",
  props: {
    eventId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      showLoader: true,
      iconUrl: "https:",
      eventData: null,
    };
  },
  methods: {
    async fetchEventData() {
      this.showLoader = true;
      const formData = new FormData();
      formData.append("event_id", this.eventId);

      try {
        let response = await axios.post("/event/getApiWeatherData", formData);
        console.log("hellow");
        if (response.data.statusCode === 200) {
          this.eventData = response.data.data;
        } else {
          console.error("Weather API Error:", response.data.error);
        }
      } catch (error) {
        console.error("Request Failed:", error);
      } finally {
        this.showLoader = false;
      }
    },
    formatDateWeather(date) {
      const options = { weekday: "short", month: "short", day: "numeric" };
      const datePart = new Date(date).toLocaleDateString(undefined, options);
      const yearPart = new Date(date).getFullYear();
      return { date: datePart, year: yearPart };
    },
  },
  mounted() {
    this.fetchEventData();
  },
};
</script>
  
  <style>
</style>
  